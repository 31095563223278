<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2>개인정보처리방침</h2>
				<a @click="onBack()" class="btnBack">이전 페이지</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="boxWhite">
			<div class="contents">
				<div class="inner">
					<!-- txtArea -->
					<div class="txtArea">
						<pre v-if="term2.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
							term2.content.replace(/ /g, '&nbsp;')
						}}</pre>
					</div>
					<!--// txtArea -->
				</div>
			</div>
		</div>
		<!--// contents -->
	</div>
	<!--// container -->
</template>
<script>
import terms from '@/api/terms';
import { isAuthorized } from '@/utils/cmm';
export default {
	mounted() {
		this.getTerms();
	},
	data() {
		return {
			term1: '',
			term2: '',
		};
	},
	methods: {
		async getTerms() {
			let res = await terms.get();
			this.term1 = res.data[0];
			this.term2 = res.data[1];
		},
		onBack() {
			isAuthorized() ? this.$router.go(-1) : this.$router.push({ name: 'login' });
		},
	},
};
</script>

<style scoped></style>
